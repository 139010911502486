export const Branding = `ATMS`;
export const PoweredBy = String(process.env.REACT_APP_POWERED_BY || "Auriga IT Solutions Pvt. Ltd.")
export const UNAUTHORIZED_STATUS_CODE = 401;
export const NOT_FOUND_STATUS_CODE = 404;
export const ACCESS = `access`;
export const REFRESH = `refresh`;
export const SUPERUSER = `superuser`;
export const USERID = 'userId';
export const REFRESH_AT = `refresh_at`;
export const PERM = `perm`;
export const EXPIRED_AT = `expired_at`;
export const SYSTEM_SETTINGS = `systemSettings`;
export const USER = `user`;
export const DEVICES = 'devices';
export const PACKAGES = 'packages';
export const ALL_PACKAGES = 'allPackages';
export const CHAINAGE = 'chainage';
export const ALL_CHAINAGE = 'all_chainage';
export const ALLOWED_CHAINAGE = 'allowed_chainage';
export const THEME_TYPE = 'theme_type';
export const LIGHT_THEME = 'light';
export const DARK_THEME = 'dark';
export const DEMO_MODE = 'demo_mode';
export const DEMO_MODE_OFF = 'off';
export const USER_LEVEL = 'user_level';
export const DEMO_MODE_ON = 'on';
export const USER_MANUAL = 'user_manual';
export const LOGGED_IN_USER_DISPLAY = `logged_in_user_display_name`;
export const LIVE_FEED_BASE_URL = String(process.env.REACT_APP_LIVE_CAMERA_FEED_URL);
export const ANNOTATION_URL = String(process.env.REACT_APP_ANNOTATION_URL);
export const GIS_URL = String(process.env.REACT_APP_GIS_BASE_URL);
export const LIVE_FEED_HOST_URL = String(process.env.REACT_APP_LIVE_CAMERA_BASE_API_URL);
export const LIVE_FEED_USERNAME = String(process.env.REACT_APP_LIVE_CAMERA_API_CREDENTIAL_USERNAME);
export const LIVE_FEED_PASSWORD = String(process.env.REACT_APP_LIVE_CAMERA_API_CREDENTIAL_PASSWORD);
export const API_HOST = String(process.env.REACT_APP_API_HOST);
export const SOCKET_URL = String(process.env.REACT_APP_SOCKET_URL);
export const BACKEND_DASHBOARD = String(process.env.BACKEND_DASHBOARD);
export const VMS_DEVICE_SDK_SOCKET_URL = String(process.env.REACT_APP_VMS_DEVICE_SDK_SOCKET_URL);
export const AGORA_APP_ID = String(process.env.REACT_APP_AGORA_APP_ID);
export const AGORA_APP_CERTIFICATE = String(process.env.REACT_APP_AGORA_APP_CERTIFICATE);
export const VIDEO_RECORDING_SERVER_URL = String(process.env.REACT_APP_VIDEO_RECORDING_SERVER_URL);
export const WHITE_MAIN_LOGO = String(process.env.REACT_APP_WHITE_THEME_BRAND_LOGO_PATH);
export const BLACK_MAIN_LOGO = String(process.env.REACT_APP_BLACK_THEME_BRAND_LOGO_PATH);
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const FAILURE_CODE = 300;
export const MAX_SPEED_LIMIT = 80;
export const MEDIA_TYPE_MSGS = ['image', 'video'];
export const MESSAGE_IMAGE_SIZE_ALLOWED = 5120; // size in kb
export const PROJECT_SUBTITLE = `Advanced Traffic Management System`;
export const DEVICE_TYPE_CAMERA = 'camera';
export const DEVICE_TYPE_VIDS = 'vids';
export const DEVICE_TYPE_VMS = 'vms';
export const DEVICE_TYPE_ECB = 'ecb';
export const DEVICE_TYPE_TMCS = 'tmcs';
export const NOT_STARTED_LOADING = 'not started loading';
export const LOADING = 'Loading...';
export const MEDIA_SERVER_STREAM_BASE_URL = 'http://107.20.122.121:5080/LiveApp/play.html?name=';
export const GOOGLE_MAP_KML_FILE = [
  'http://107.20.122.121:8080/static/atms_demo/kml_files/dve_package_1.kml'
  // 'http://107.20.122.121:8080/static/atms_demo/package1.kml',
  // 'http://107.20.122.121:8080/static/atms_demo/kml_files/dve_package_5.kml',
  // 'http://107.20.122.121:8080/static/atms_demo/kml_files/dve_package_4.kml'
];
export const PRIORITY_CLASSES = { 1: 'bg-success', 2: 'bg-warning', 3: 'bg-danger' };
export const MAX_EVENT_DATA = 250;
export const BACK = '< Back';
export const CALL_TYPE_AUDIO = 'audio';
export const CALL_TYPE_VIDEO = 'video';
export const INITIATED_FROM_DASHBOARD = 'dashboard';
export const MAX_GLOBAL_DATA = 100;
export const DEMO_MODE_TOTAL_COUNT = 260;
export const DEMO_MODE_TOTAL_COUNT_VSDS = 257;
export const DEMO_PACKAGE_DATA = String(process.env.REACT_APP_DEMO_PACKAGE_DATA) || 4;
export const DEMO_MODE_DISPLAY = String(process.env.REACT_APP_DEMO_MODE_DISLAY) || false;
export const CAMERASLUGS = ['vids_camera', 'anpr_camera', 'ptz_camera', 'camera'];
export const VSDSCAMERASLUG = 'anpr_camera';
export const VIDSCAMERASLUG = 'vids_camera';
export const ROISLUGS = ['lpu'];
export const CAMERA_ROI = 'camera_roi';
export const ADAS_SLUG = 'adas';
export const TMCS_MODULE_SLUG = 'tmcs';
export const BUILD_VERSION = '7.0.0';
export const exacVRSServer = 'exacq_vrs_server';
export const i2vVRSServer = 'i2v_vrs_server';
export const DEVICE_VALIDATION = [
  { id: 'package_id', key: 'package_mandatory' },
  { id: 'location_id', key: 'location_mandatory' },
  { id: 'direction', key: 'direction_mandatory' },
  { id: 'lat', key: 'coordinates_mandatory' },
  { id: 'lng', key: 'coordinates_mandatory' },
  { id: 'ip_address', key: 'ip_mandatory' }
  // { id: 'hardware_vendor_id', key: 'hardware_vendor_mandatory' }
];
export const VIDS_MODULE_SLUG = 'vids';
export const VSDS_MODULE_SLUG = 'vsds';
export const AVG_SPEED = 80;
export const NOT_FOUND = 'Not Found';
// Alert Status
export const ALERT_STATUS = {
  active: 'active',
  fake: 'mark_as_fake',
  duplicate: 'mark_as_duplicate',
  event_created: 'event_created',
  event_closed: 'event_closed',
  accident_reported: 'accident_reported',
  accident_closed: 'accident_closed'
};

export const ALERT_STATUS_TEXT = {
  '': 'Unknown',
  active: 'Active',
  mark_as_fake: 'Marked as Fake Alert',
  mark_as_duplicate: 'Marked as Duplicate Alert',
  event_created: 'Event Created',
  event_closed: 'Event Closed',
  accident_reported: 'Accident Reported',
  accident_closed: 'Accident Closed'
};

export const DIRECTION = {
  lhs: 'LHS',
  rhs: 'RHS',
  median: 'MEDIAN'
};

export const Direction = [
  { id: 'lhs', name: 'LHS' },
  { id: 'rhs', name: 'RHS' }
];

export const FILTER = [
  {
    id: true,
    name: 'Yes'
  },
  {
    id: false,
    name: 'No'
  }
];

export const FILTERS = [
  {
    id: 'yes',
    name: 'Yes'
  },
  {
    id: 'no',
    name: 'No'
  }
];

export const SCROLLTIMEOBJECT = [
  { id: 10000, name: '10 sec' },
  { id: 20000, name: '20 sec' },
  { id: 30000, name: '30 sec' },
  { id: 40000, name: '40 sec' },
  { id: 50000, name: '50 sec' },
  { id: 60000, name: '60 sec' }
];

export const ModuleID = {
  VIDS: 2,
  VSDS: 3,
  ECB: 5,
  VMS: 4
};

export const moduleFilter = [
  {
    id: 'vids',
    name: 'VIDS'
  },
  {
    id: 'vsds',
    name: 'VSDS'
  }
];

export const VEHICLEMAPPING = {
  CAR: 2,
  BUS: 5,
  TRUCK: 7,
  TWO_WHEELER: 3,
  PEOPLE: 0
};
export const VEHICLEMAPPINGTYPE = {
  2: 'Car/Jeep',
  5: 'Bus',
  7: 'Truck',
  3: 'Motorbike',
  0: 'Person'
};
export const VEHICLE_TYPE = {
  1: 'ambulance',
  2: 'road_patrolling_vehicle',
  3: 'maintenance_vehicle',
  4: 'fire_brigade'
};
export const DEFAULT_CENTER =
  localStorage.getItem('systemSettings') &&
  JSON.parse(JSON.parse(localStorage.getItem('systemSettings'))?.map_default_init_coordinate);

export const DISPLAY_HEALTH_MAP_LOCATION = true;

export const PASSING_VEHICLE_SPEED_FILTER = [
  { id: '>200', name: 'Above 200' },
  { id: '>180', name: 'Above 180' },
  { id: '>150', name: 'Above 150' },
  { id: '>120', name: 'Above 120' },
  { id: '>100', name: 'Above 100' },
  { id: '>80', name: 'Above 80' },
  { id: '<80', name: 'Below 80' },
  { id: '<60', name: 'Below 60' }
];

export const LANE_ID = [
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
  { id: '5', name: '5' }
];

export const VEHICLE_NUMBER_PLATE = [
  { id: true, name: 'Standard' },
  { id: false, name: 'Non-Standard' },
  { id: 'no_number_plate', name: 'Without Number Plate' }
];

export const CHALLAN_PENDING = 'CHALLAN_INITIALIZED';
export const CHALLAN_PUSHED = 'CHALLAN_PUSHED';
export const CHALLAN_REJECTED = 'CHALLAN_REJECTED';
export const DENIED_BY_NIC = 'DENIED_BY_NIC';

export const CHALLAN_FILTER = [
  { id: 'CHALLAN_INITIALIZED', name: 'INITIALIZED' },
  { id: 'CHALLAN_PUSHED', name: 'PUSHED' },
  { id: 'CHALLAN_REJECTED', name: 'REJECTED' },
  { id: 'DENIED_BY_NIC', name: 'DENIED BY NIC' }
];

export const directionData = [
  {
    id: 'lhs',
    name: 'LHS'
  },
  {
    id: 'rhs',
    name: 'RHS'
  }
];

export const LEVEL_1 = 'level_1';
export const LEVEL_2 = 'level_2';
export const LEVEL_3 = 'level_3';

export const USER_LEVEL_LIST = [
  { id: 'level_1', name: 'Level 1' },
  { id: 'level_2', name: 'Level 2' },
  { id: 'level_3', name: 'Level 3' }
];

export const LEVELS = {
  level_1: 'Level 1',
  level_2: 'Level 2',
  level_3: 'Level 3'
};

export const MANUAL_DETECTION_TYPE = [
  {
    id: 'bad_road_condition',
    name: 'Bad Road condition'
  },
  { id: 'work_in_progress', name: 'Work in progress' }
];

export const MANUAL_DETECTIONS = {
  bad_road_condition: 'Bad Road condition',
  work_in_progress: 'Work in progress'
};

export const CHALLAN_GENERATION_MODE = [
  {
    id: 'auto',
    name: 'Auto'
  },
  {
    id: 'manual',
    name: 'Manual'
  }
];

export const TIMEFILTER = [
  {
    id: 10,
    name: '10 Sec'
  },
  {
    id: 20,
    name: '20 Sec'
  },
  {
    id: 30,
    name: '30 Sec'
  },
  {
    id: 60,
    name: '1 Min'
  },
  {
    id: 120,
    name: '2 Min'
  },
  {
    id: 300,
    name: '5 Min'
  },
  {
    id: 600,
    name: '10 Min'
  }
];

export const hourFilter = [
  { name: 'Last 1 hour', id: 1 },
  { name: 'Last 12 hours', id: 12 },
  { name: 'Last 24 Hours', id: 24 }
];
