import moment from 'moment';
import { format, formatDistanceToNow, parse } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fDateNew(date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fDatePayloadFormat(date) {
  return format(new Date(date), 'yyyy-MM-dd HH:mm');
}

export function fDatePayloadFormat1(date) {
  return format(new Date(date), 'dd-MM-yyyy HH:mm a');
}

export function fDateTimeFormat12Hours(date, second = true) {
  const dateObject = new Date(date);

  // Get the components of the date and time
  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObject.getDate().toString().padStart(2, '0');

  // Get the time components
  let hours = dateObject.getHours();
  const minutes = dateObject.getMinutes().toString().padStart(2, '0');
  const seconds = dateObject.getSeconds().toString().padStart(2, '0');

  // Determine whether it's AM or PM
  const period = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  hours = hours % 12 || 12;

  // Create the formatted string
  const formattedTimestamp = `${day}-${month}-${year}- ${hours}:${minutes}${
    second ? `:${seconds}` : ''
  } ${period}`;
  return formattedTimestamp;
}

export function fDateTime(date) {
  try {
   
    return format(new Date(date), 'dd-MM-yyyy HH:mm');
  } catch (error) {
    console.error(`Error processing date: ${date}`, error);
    // Handle the error or return a default value if needed
    return 'Invalid Date';
  }
}

export function fDateTimeS(date) {
  return format(new Date(date), 'dd-MM-yyyy HH:mm:ss a');
}

export function fDateTimeformat(date) {
  return format(new Date(date), 'dd-MM-yyyy HH:mm:ss a');
}

export function fParseDateTimeformat(date) {
  const parseDate = parse(date, 'dd-MM-yyyy hh:mm a', new Date())
  return format(parseDate, 'dd-MM-yyyy HH:mm:ss a');
}

export function fDateTimeGraph(date) {
  return format(new Date(date), 'dd MMM KK:mm a');
}
export function fTimeFormat(date) {
  return format(new Date(date), 'KK:mm a');
}
export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd-MM-yyyy pp');
}

export function fToNow(date, suffix = true) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: suffix
  });
}

export function fToNowString(date, suffix = true) {
  // result dateformat 3-2-2015 20:21:44
  const dateTimeSplit = date.split(' ');
  const dateSplit = dateTimeSplit[0]?.split('-');
  const timeSplit = dateTimeSplit[1]?.split(':');
  [dateSplit[0], dateSplit[1]] = [dateSplit[1], dateSplit[0]];
  const dateNew = [dateSplit.join('-'), timeSplit.join(':')];

  return formatDistanceToNow(new Date(dateNew.join(' ')), {
    addSuffix: suffix
  });
}

export function fTimeDiff(date1, date2) {
  return moment
    .utc(moment(date1, 'DD-MM-YYYY HH:mm:ss').diff(moment(date2, 'DD-MM-YYYY HH:mm:ss')))
    .format('HH:mm:ss');
}

export function fTimeDiff2(date1, date2) {
  const timeDifference = new Date(date2) - new Date(date1);
  const totalSeconds = Math.floor(timeDifference / 1000);
  const minutes = totalSeconds / 60;
  return minutes;
}

export function fTimeDiff1(date1, date2) {
  return moment.utc(moment(date1, 'HH:mm:ss').diff(moment(date2, 'HH:mm:ss'))).format('HH:mm:ss');
}

export function dateDiff(date1, date2) {
  const currentDate = new Date(date1);
  const examDate = new Date(date2);
  const timeDifference = Math.abs(examDate - currentDate);
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return daysRemaining;
}

export function getDay(date) {
  return format(new Date(date), 'dd');
}
export function getHour(date) {
  return format(new Date(date), 'HH');
}

export function getMinutes(date) {
  return format(new Date(date), 'mm');
}

export function getSecond(date) {
  return format(new Date(date), 'ss');
}

export function timeDateFormat(date) {
  return format(new Date(date), 'hh:mm a, dd MMM yyyy');
}

export function dateTimeSlash(date) {
  return format(new Date(date), 'hh:mm a, dd/MM/yyyy');
}

export function getMinutestoHour(min) {
  const hours = min / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  let time = 0;
  if (rhours) {
    time = `${rhours} hour ${rminutes} minute`;
  } else {
    time = `${rminutes} minute`;
  }
  return time;
}

export function getSecondstoMinutes(duration) {
  if (!Number.isNaN(duration) && duration > 0) {
    const hrs = Math.floor(duration / 3600);
    const mins = Math.floor((duration % 3600) / 60);
    const secs = Math.floor(duration % 60);
    return mins;
  }

  return 0;
}

export function convertDate(value) {
  if (value) {
    const parts = value.split(/[- :]/);
    const formattedDate = new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4]);
    return formattedDate;
  }
}
